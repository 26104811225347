import { ref } from "vue";
import { createStore } from "vuex";

export default createStore({
  state: {
    use: {},
  },
  getters: {},
  mutations: {
    setue(state, val: any) {
      localStorage.setItem("use", JSON.stringify(val));
      state.use = val;
    },
  },
  actions: {},
  modules: {},
});
