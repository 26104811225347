import axios from "axios";
import { h, ref } from "vue";
import { ElNotification, ElLoading } from "element-plus";
import { useRouter } from "vue-router";
import routes from "@/router/index";
import router from "@/router";
import {} from "@/utils/common";
// const router = useRouter();
const type = (method: any) => {
  let sendData = "params";
  if (["PUT", "POST", "PATCH"].includes(method.toUpperCase())) {
    sendData = "data";
  }
  return sendData;
};
const instance = axios.create({
  baseURL: "/api",
  timeout: 100000,
});
/*请求拦截器*/
// let loading = ElLoading.service();
instance.interceptors.request.use(
  (config: any) => {
    // loading = ElLoading.service({
    //   lock: true,
    //   text: "Loading",
    //   background: "rgba(0, 0, 0, 1)",
    // });
    console.log("请求数据前", config.data);
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
/*响应拦截器*/
instance.interceptors.response.use(
  async (response: any) => {
    if (response.data.code == 401 && routes.currentRoute.value.path != "/") {
      router.push({
        path: "/",
      });
      sessionStorage.setItem("arr", JSON.stringify([]));
      localStorage.setItem("use", JSON.stringify([]));
      return;
    }
    // console.log("请求数据后code", response.data.code);
    // console.log("请求数据后", response.data);
    return response;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
const request = (url: string, method = "get", data = {}) => {
  const heighttypedata = ref<any>([
    "/admin/basicdata/upload/avatar",
    "/admin/basicdata/upload/file",
    "/admin/basicdata/upload/tmpfile",
    "/admin/resource/upload/file",
  ]);
  const heighttype = ref<any>();
  if (heighttypedata.value.includes(url)) {
    heighttype.value = {
      "Content-Type": "multipart/form-data",
    };
  } else {
    heighttype.value = {
      "Content-Type": "application/json",
    };
  }
  return new Promise((reslove, reject) => {
    instance({
      url: url,
      method: method,
      headers: heighttype.value,
      [type(method)]: data,
      responseType: "json",
    })
      .then((res: any) => {
        reslove(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};
export default request;
