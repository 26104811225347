<template>
  <router-view />
</template>

<script setup>
import { setassignment } from "@/utils/common";
import { admin, permission, huoqusuoyou } from "@/axios/index"; //引入接口示例
import { onBeforeMount, onMounted } from "vue";
// 调用接口示例
const admins = async () => {
  let { data } = await admin();
  setassignment(data.data);
};

onBeforeMount(() => {
  admins();
});
// onMounted(() => {

// });
</script>

<style lang="scss">
@import "@/elestyle/index.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul,
ol,
li,
i {
  list-style: none;
  font-style: normal;
}
.marbot {
  margin-bottom: 10px;
  text-align: left;
}
.inputall {
  display: inline-block;
  text-align: left;
  padding: 15px 0;
  margin-right: 30px;
}
.inputtext {
  display: inline-block;
  font-style: normal;
  color: #ababab;
  text-align: right;
  padding-right: 10px;
  font-size: 14px;
  // width: 100px;
  font-weight: 600;
  vertical-align: middle;
}
.inquire {
  display: inline-block;
  background-color: #3e6db5;
  border-radius: 5px;
  color: #ffffff;
  font-size: 14px;
  padding: 0 30px;
  height: 32px;
  font-style: normal;
  line-height: 32px;
  cursor: pointer;
}
.reset {
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 30px;
  line-height: 32px;
  height: 32px;
  cursor: pointer;
  border: 1px solid #ebebeb;
  color: #999999;
  font-style: normal;
}
.butsp {
  text-align: left;
  margin-bottom: 10px;
}
// 添加
.butadd {
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 30px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  color: #ffffff;
  font-style: normal;
  margin-right: 10px;
  background-color: #3e6db5;
}
// 删除按钮
.butanuoff {
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 30px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  color: #ffffff;
  font-style: normal;
  margin-right: 10px;
  background-color: #d1242a;
}
// 打印
.butprint {
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 30px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  color: #ffffff;
  font-style: normal;
  margin-right: 10px;
  background-color: #23bcca;
}
// 导入
.butchannel {
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 30px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  color: #ffffff;
  font-style: normal;
  margin-right: 10px;
  background-color: rgba(255, 69, 0, 1);
}
// 导出
.butsderive {
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 30px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  color: #ffffff;
  font-style: normal;
  margin-right: 10px;
  background-color: #ff9f24;
}
//一键毕业/禁用
.butallgra {
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 30px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  color: #ffffff;
  font-style: normal;
  margin-right: 10px;
  background-color: rgba(13, 155, 157, 1);
}
//一键在读/启用
.butallrea {
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 30px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  color: #ffffff;
  font-style: normal;
  margin-right: 10px;
  background-color: rgba(5, 147, 5, 1);
}
// 更改状态
.buttransi {
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 30px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  color: #ffffff;
  font-style: normal;
  margin-right: 10px;
  background-color: rgba(225, 101, 63, 1);
}
// 标题
.ps {
  height: 30px;
  line-height: 30px;
  font-weight: 800;
  text-align: left;
  margin-top: 10px;
  box-sizing: border-box;
  .biao {
    display: inline-block;
    width: 5px;
    height: 15px;
    line-height: 30px;
    background-color: #409eff;
    margin-right: 5px;
    margin-top: 5px;
  }
}
// 背景
.hint {
  max-width: 900px;
  padding: 20px;
  margin-top: 20px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}
// 背景2
.hinttwo {
  padding: 20px;
  margin-top: 5px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-sizing: border-box;
}
// 
.equipment_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0px;
  li {
    border: 1px solid #ddd;
    display: flex;
    width: 33%;
    height: 40px;
    line-height: 40px;
    border-radius: 0;
    div {
      &:nth-child(1) {
        background-color: rgb(247, 247, 247);
        width: 30%;
        padding-right: 10px;
        text-align: right;
        overflow: hidden; // 超出的文本隐藏
        text-overflow: ellipsis; // 溢出用省略号显示
        white-space: nowrap; // 溢出不换行
      }
      &:nth-child(2) {
        padding-left: 20px;
        width: 70%;
        overflow: hidden; // 超出的文本隐藏
        text-overflow: ellipsis; // 溢出用省略号显示
        white-space: nowrap; // 溢出不换行
      }
    }
  }
}
// 加减
.jian,
.jia {
  display: inline-block;
  width: 20px;
  line-height: 19px;
  margin-left: 20px;
  cursor: pointer;
  height: 20px;
  background-color: #c8c8c8;
  border-radius: 50%;
  color: #ffffff;
  font-weight: 900;
  text-align: center;
  position: relative;
  top: 10px;
  > i {
    display: inline-block;
    margin-top: 0px;
    position: absolute;
    left: 0;
    top: 2px;
    bottom: 0;
    right: 0;
    margin: auto;
  }
}
// 表格人员渲染样式
.staff {
  display: inline-block;
  border-radius: 8px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #3e6db5;
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #3e6db5;
  line-height: 40px;
  margin: 0 2px;
  padding: 0 3px;
  box-sizing: border-box;
}
.equipments {
  border-radius: 8px;
  background: #ffffff;
  border-radius: 4px;
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  margin: 2px 0;
  padding: 0 3px;
  box-sizing: border-box;
  &:nth-child(odd) {
    border: 1px solid cornflowerblue;
    color: cornflowerblue;
  }
  &:nth-child(even) {
    border: 1px solid #23bcca;
    color: #23bcca;
  }
}
// 绿色
.green {
  color: green;
}
.bggreen {
  background-color: green;
}
// 红色
.red {
  color: red;
}
.bgred {
  background-color: red;
}
// 黄色
.orange {
  color: orange;
}
.bgorange {
  background-color: orange;
}
// 灰白色
.gray {
  color: #333333;
}
.bggray {
  background-color: gray;
}
// 蓝色
.blue {
  color: blue;
}
.bgblue {
  background-color: blue;
}
// 紫色
.zise {
  color: #8256e8;
}
.bgzise {
  background-color: #8256e8;
}
.yincang {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.w50 {
  width: 50%;
}
.w60 {
  width: 60%;
}
.w70 {
  width: 70%;
}
.w80 {
  width: 80%;
}
.w90 {
  width: 90%;
}
.w100 {
  width: 100%;
}

// 修改进度条样式
// #nprogress .bar {
//   background-color: red !important;
// }
// .el-menu li:nth-child(3) {
//   margin-left: -18px;
// }

#box-card {
  box-sizing: border-box;
  width: 100%;
  height: 96px;
  position: relative;
  transition: max-height 0.5s ease;
}
#fold {
  position: absolute;
  bottom: 0;
  right: 0;
}
#aaa {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  transition: max-height 0.5s ease;
}
.el-dialog__headerbtn {
  top: -4px !important;
}
button.el-button.el-button--primary.el-button--small.is-link {
  color: #409eff !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fuzu {
  display: flex;
}
.xiazai {
  display: inline-block;
}
.xiazai2 {
  width: 200px;
}
.el-menu {
  overflow: auto;
  scrollbar-width: none; // 兼容火狐
  -ms-overflow-style: none; // 兼容IE10+
  /*  解决 ios 上滑动不流畅 */
  -webkit-overflow-scrolling: touch;
}
::-webkit-scrollbar {
  display: none;
}
.el-menu-item-group__title {
  display: none;
}

.d-f {
  display: flex;
  align-items: center;
}
.j-b {
  justify-content: space-between;
}
.j-a {
  justify-content: space-around;
}
.j-c {
  justify-content: center;
}
.c33 {
  color: #333;
}
.cff {
  color: #fff;
}
.c99 {
  color: #999999;
}
.c66 {
  color: #666666;
}
.cc6 {
  color: #c6c6c6;
}
.c3e {
  color: #3e6db5;
}
.cd1 {
  color: #d1242a;
}
.f-w {
  font-weight: bold;
}
.f6 {
  font-size: 6px;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
.f24 {
  font-size: 24px;
}
.f28 {
  font-size: 28px;
}
.p-r {
  position: relative;
}
.p-a {
  position: absolute;
}
.t-l {
  text-align: left;
}
.ov {
  overflow: hidden; // 超出的文本隐藏
  text-overflow: ellipsis; // 溢出用省略号显示
  white-space: nowrap; // 溢出不换行
}
</style>
