import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import routes from "./routerdata";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { ElNotification } from "element-plus";
import {
  huoqusuoyou,
  permission,
  moduleMenu,
  unlogin,
  getAdminInfo,
} from "@/axios/index"; //引入接口示例
import { modulefun, powersTf, buttonfun, newpowersTf } from "@/utils/common";
// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes,
// });
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
import { ref } from "vue";
import store from "@/store";
router.beforeEach(async (to: any, from: any, next: any) => {
  const info = ref<any>(localStorage.getItem("info"));
  if (info.value != null) {
    info.value = JSON.parse(info.value);
    window.document.title = info.value?.school_name;
    const link = ref<any>(
      document.querySelector("link[rel*='icon']") ||
        document.createElement("link")
    );
    link.value.type = "image/x-icon";
    link.value.rel = "shortcut icon";
    link.value.href = "/api" + info.value.school_logo;
    document.getElementsByTagName("head")[0].appendChild(link.value);
  }
  if (to.name !== "loGin") {
    await huoqusuoyous(to.meta.id);
    await permissions();
    const a = ref<any>(localStorage.getItem("use"));
    if (a.value == "undefined") {
      const { data }: any = await getAdminInfo({});
      store.commit("setue", data.data);
    } else {
      a.value = JSON.parse(a.value);
      if (a.value == null) {
        const { data }: any = await getAdminInfo({});
        store.commit("setue", data.data);
      } else if (a.value.length == 0) {
        const { data }: any = await getAdminInfo({});
        store.commit("setue", data.data);
      } else {
        store.commit("setue", a.value);
      }
    }
  }
  NProgress.start();
  next();
});
router.afterEach((to: any, from: any) => {
  NProgress.done(); // 进度条结束
});
// 模块权限接口
const huoqusuoyous = async (val: any) => {
  const { data }: any = await moduleMenu({});
  const newdata = ref<any>();
  if (data.code == 0) {
    if (val !== undefined) {
      data.data.forEach((item: any) => {
        if (item.id == val) {
          console.log("%c 模块权限!", "color:red", router);
          newdata.value = item;
        }
      });
      modulefun(data.data, routes[1].children);
    }
  } else {
    ElNotification({
      title: "提示",
      message: data.msg,
    });
  }
};
// 按钮权限
const permissions = async () => {
  const { data }: any = await permission({});
  console.log("%c 按钮权限!", "color:red", data.data);
  buttonfun(data.data);
  newpowersTf(data.data);
};
// 瑞出登陆
const unloginfun = async () => {
  sessionStorage.setItem("arr", JSON.stringify([]));
  localStorage.setItem("use", JSON.stringify([]));
  let { data }: any = await unlogin({});
  if (data.code == 0) {
    ElNotification({
      title: "提示",
      message: "退出登录",
      type: "success",
    });
    router.push({
      path: "/",
    });
  }
};
// 加载条的配置
// NProgress.configure({
//   easing: 'ease', // 动画方式
//   speed: 500, // 递增进度条的速度
//   showSpinner: false, // 是否显示加载 icon
//   trickleSpeed: 200, // 自动递增间隔
//   minimum: 0.3 // 初始化时的最小百分比
// })

export default router;
