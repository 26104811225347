import { ref } from "vue";
import routes from "@/router/routerdata";
import { allpower } from "@/axios/index"; //引入接口示例
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();

const publicArrDtata = ref<any>({});
const char = ref(null);
const setassignment = (arrData: any) => {
  publicArrDtata.value = arrData;
};
const getassignment = (arrData: any, dataKey: any) => {
  if (arrData !== undefined) {
    arrData.forEach((element: any) => {
      element[dataKey] = publicArrDtata.value[dataKey][element[dataKey]];
    });
  }
};
const getassignmentArr = (arrData: any, dataKey: any) => {
  const char = ref();
  dataKey.forEach((item: any) => {
    char.value = item;
    console.log("传入数据", arrData);
    arrData.forEach((element: any) => {
      element[char.value] =
        publicArrDtata.value[char.value][element[char.value]];
    });
  });
};
const getassignments = (arrData: any) => {
  console.log();
};
const setassignments = (arrData: any) => {
  // console.log("传入数据", arrData);
  for (const key in arrData) {
    for (const keys in publicArrDtata.value) {
      if (key == keys) {
        for (const item in publicArrDtata.value[key]) {
          if (arrData[key] == publicArrDtata.value[key][item]) {
            arrData[key] = item;
          }
        }
      }
    }
  }
};
// 字典下拉框
const alterSelect = () => {
  const a = ref([publicArrDtata.value]);
  const b = ref({});
  a.value.forEach((element: any, index: number) => {
    for (const key in element) {
      const arrref = ref<any>([]);
      for (const keys in element[key]) {
        arrref.value.push({ title: element[key][keys], value: keys });
      }
      Reflect.set(b.value, key, arrref.value);
    }
  });
  return b.value;
};
// 模块权限
const moduledata = ref<any>([]);
const routedata = ref<any>([]);
const modulefun = (data: any, route: any) => {
  moduledata.value = data;
  routedata.value = route;
};
// 模块显示
const moduleTf = (val: any) => {
  const a = ref<any>(false);
  moduledata.value.forEach((item: any) => {
    if (item.key == val) {
      a.value = true;
      return;
    }
  });
  return a.value;
};
// 菜单显示
const powersTf = async (val: any) => {
  if (val !== undefined) {
    routedata.value.forEach((item: any) => {
      if (item.meta.id == val.id) {
        val.children.forEach((i: any) => {
          item.children.forEach((element: any) => {
            if (element.children !== undefined) {
              element.children.forEach((isc: any) => {
                if (isc.meta.value == i.id) {
                  console.log("%c 菜单信息!", "color:red", element);
                  element.meta.show = true;
                  isc.meta.show = true;
                  return;
                }
                // console.log("%c 信息!", "color:red", isc.meta, isc.meta.show);
              });
            } else {
              if (element.meta.value == i.id) {
                element.meta.show = true;
                return;
              }
            }
          });
        });
      }
    });
  }
};
// 新菜单权限
const newpowersTf = (val: any) => {
  if (val !== undefined) {
    routedata.value.forEach((item: any) => {
      if (item.children != undefined) {
        item.children.forEach((element: any) => {
          if (element.children != undefined) {
            element.children.forEach((isc: any) => {
              val.forEach((i: any) => {
                if (isc.meta.value == i) {
                  element.meta.show = true;
                  isc.meta.show = true;
                  return;
                }
              });
            });
          } else {
            val.forEach((i: any) => {
              if (element.meta.value == i) {
                element.meta.show = true;
                return;
              }
            });
          }
        });
      }
    });
  }
};
// 按钮数据
const buttondata = ref<any>([]);
const buttonfun = (val: any) => {
  buttondata.value = val;
  // console.log("%c 按钮权限!", "color:red", buttondata.value);
};
// 按钮判断
const buttfun = (val: any) => {
  const a = ref<any>(false);
  if (buttondata.value != undefined) {
    buttondata.value.forEach((item: any) => {
      if (item == val) {
        a.value = true;
        return;
      }
    });
  }
  return a.value;
};
// 无数据处理
const undernulmethod = (val: any) => {
  val.forEach((item: any) => {
    const a = Object.keys(item);
    for (let index = 0; index < a.length; index++) {
      if (item[a[index]] == undefined || item[a[index]] == null) {
        item[a[index]] = "";
      }
    }
  });
  return val;
};
// 去重操作  1.数组 2.key值
const qu = (datas: any, qudata: any) => {
  const newdata = ref<any>([]);
  const a = ref<any>([]);
  a.value = datas
    .map((item: any) => {
      return item[qudata];
    })
    .filter((i: any) => i);
  a.value.forEach((item: any) => {
    if (!newdata.value.includes(item)) {
      newdata.value.push(item);
    }
  });
  return newdata.value;
};
// 星期渲染
const weeksta = (val: any) => {
  const obj = <any>{
    1: "周一",
    2: "周二",
    3: "周三",
    4: "周四",
    5: "周五",
    6: "周六",
    7: "周日",
  };
  return obj[val];
};
// 天数大写
const daysta = (val: any) => {
  const obj = <any>{
    1: "一",
    2: "二",
    3: "三",
    4: "四",
    5: "五",
    6: "六",
    7: "七",
    8: "八",
    9: "九",
    10: "十",
  };
  return obj[val];
};
export {
  daysta,
  weeksta,
  setassignment,
  getassignment,
  getassignmentArr,
  getassignments,
  setassignments,
  alterSelect,
  modulefun,
  moduleTf,
  powersTf,
  buttonfun,
  buttfun,
  undernulmethod,
  newpowersTf,
  qu,
};
